.typicalPortfolio-img {
    transform: translate3d(0, 83%, 0);
}
.balanced-scale-label {
    transform: translate3d(-50%, 0%, 0) scale(1);
}

.typical-portfolio-part {
    height: 260px;
}

@media (max-width: 1023px) {
    .balanced-scale-label {
        transform: translate3d(-50%, 0%, 0) scale(0.8);
    }
    .typical-portfolio-part {
        height: 222px;
    }
}

@media (max-width: 768px) {
    .typical-portfolio-part {
        height: 200px;
    }
    .balanced-scale-label {
        transform: translate3d(-50%, 13%, 0) scale(0.6);
    }
    .slide-toggle-buttons {
        max-width: 460px;
    }
}

@media (min-width: 425px) and (max-width: 460px) {
    .typical-portfolio-part {
        height: 140px !important;
    }
}

@media (max-width: 639px) {
    .typical-portfolio-part {
        height: 160px;
    }
    .slide-toggle-buttons {
        max-width: 460px;
    }
}

@media (max-width: 460px) {
    .balanced-scale-label {
        transform: translate3d(-50%, 24%, 0) scale(0.4);
    }
}

@media (max-width: 424px) {
    .typical-portfolio-part {
        height: 114px;
    }
}
