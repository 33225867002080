.jiraafUPS-PredictableReturns-img {
    transform: translate3d(-187px, 23px, 0) scale(1);
}

@media (min-width: 1024px) and (max-width: 1280px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-143px, 60px, 0) scale(0.9) !important;
    }
}

@media (max-width: 1024px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-50px, 0px, 0px) scale(1.1);
    }
}

@media (max-width: 930px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-50px, 40px, 0px) scale(1.1);
    }
}
@media (max-width: 768px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-37px, 50px, 0px) scale(1.09);
    }
}

@media (max-width: 640px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-26px, 20px, 0px) scale(1.09);
    }
}

@media (max-width: 500px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-26px, 40px, 0px) scale(1.09);
    }
}

@media (max-width: 420px) {
    .jiraafUPS-PredictableReturns-img {
        transform: translate3d(-14px, 60px, 0px) scale(1.2);
    }
}
