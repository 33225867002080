.past-opportunities-slider {
    transition: transform 0.5s ease;
    scroll-behavior: smooth;
    height: 500px;
}

.past-opportunities-slider.disable-transitions,
.past-opportunities-slider.disable-transitions .past-opportunities-animated-item {
    transition-duration: 0s !important;
}

.past-opportunity-item-wrapper {
    max-height: 340px;
    transition: max-height 1s ease;
}

.active-slide.past-opportunity-item-wrapper {
    max-height: 650px;
}

.past-opportunity--description {
    opacity: 0;
    max-height: 0;
    transition: max-height 1s ease, opacity 1s ease;
}

.active-slide .past-opportunity--description {
    opacity: 100;
    max-height: 300px;
}

@media (max-width: 767px) {
    .past-opportunities-slider {
        height: 400px;
    }

    .past-opportunity-item-wrapper {
        max-height: 250px;
        ;
    }

    .active-slide.past-opportunity-item-wrapper {
        max-height: 520px;
    }

    .past-opportunity--description {
        max-height: 0;
    }

    .active-slide .past-opportunity--description {
        max-height: 200px;
    }
}

.past-opportunity--logo {
    transform: translateZ(0);
    transition: transform 0.5s ease;
}

.active-slide .past-opportunity--logo {
    transform: translate3d(12px, -20px, 0) scale3d(1.3, 1.3, 1);
}

.past-opportunity--logo-wrapper {
    padding-top: 24px;
    transition: padding 0.5s ease;
}

.active-slide .past-opportunity--logo-wrapper {
    padding-top: 56px;
}

.past-opportunity--content {
    transition: background-color 0.5s ease, width 0.5s ease;
}