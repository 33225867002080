.recent-deals-slider {
    transform: translate3d(-720px, 0, 0);
    transition: transform 0.75s ease;
    scroll-behavior: smooth;
}

.no-padding {
    padding-right: 0px !important;
}

.recent-deals-heading-wrapper {
    padding-left: calc(150px + 50px);
}

.recent-deals-slider.disable-transitions,
.recent-deals-slider.disable-transitions .recent-deals-animated-item {
    transition-duration: 0s !important;
}

.recent-deals-slider>li {
    padding: 0 10px 0 0;
}

.recent-deal-item-wrapper {
    transition: margin 0.5s ease, padding 0.5s ease;
}

.recent-deal-item {
    transform: translateZ(0);
    transition: width 0.5s ease, max-height 0.5s ease-in-out,
        box-shadow 0.5s ease;
}

.recent-deal--tag {
    transform: translateZ(0);
    transition: all 0.6s;
}

.recent-deal--description {
    max-height: 0;
    transform: translateZ(0);
    transition: max-height 0.8s ease, opacity 0.7s ease;
}

.active-slide .recent-deal--description {
    max-height: 125px;
}

.recent-deal--logo {
    transform: translateZ(0);
    transition: transform 0.5s ease;
}

.recent-deal--logo1 {
    transform: translateZ(0);
    transition: transform 0.5s ease;
}

.recent-deal--content {
    transition: background-color 0.5s;
}

.active-slide .recent-deal--content {
    background: theme('colors.gray.50');
}

.active-slide .recent-deal--logo {
    transform: translate3d(10px, 0, 0) scale3d(1.2, 1.2, 1);
}

.active-slide .recent-deal--logo1 {
    transform: translate3d(64px, -50%, 0) scale3d(1.5, 1.5, 1);
}

.shadow-active-recent-deal-item{
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.40), 0px 12px 12px -6px rgba(0, 0, 0, 0.16);
}

@media (max-width: 1024px) {
    .active-slide .recent-deal--logo1 {
        transform: translate3d(52px, -50%, 0) scale3d(1.5, 1.5, 1);
    }
}

@media (max-width: 768px) {
    .recent-deals-heading-wrapper {
        padding-left: calc(150px + 40px);
    }
    .active-slide .recent-deal--logo {
        transform: translate3d(10px, 0, 0) scale3d(1, 1, 1);
    }
}

@media (min-width: 640px) {
    .recent-deals-heading-wrapper {
        padding-left: calc(171px + 40px);
    }

    .recent-deals-slider>li {
        padding: 0 10px 0 0;
    }
}

@media (min-width: 768px) {
    .recent-deals-heading-wrapper {
        padding-left: calc(240px + 60px);
    }

    .recent-deals-slider>li {
        padding: 0 30px 0 0;
    }

    .recent-deals-heading-wrapper {
        padding-left: calc(231px + 60px);
    }
}

@media (min-width: 1020px) {
    .recent-deals-heading-wrapper {
        padding-left: calc(291px + 60px);
    }

    .active-slide .recent-deal--logo {
        transform: translate3d(16px, 0, 0) scale3d(1.3, 1.3, 1.3);
    }
}

@media (min-width: 1280px) {
    .recent-deals-heading-wrapper {
        padding-left: calc(350px + 80px);
        bottom: 580px;
    }

    .recent-deals-slider>li {
        padding: 0 30px 0 0;
    }
}