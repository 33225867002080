.jiraaf-usp-bgColor {
    @apply bg-purple-400/5;
}

@media (max-width: 1023px) {
    .active-slide-indicator {
        display: hidden;
    }
}

@media (min-width: 1024px) {
    .active-slide-indicator {
        display: block;
    }

    .active-slide-indicator {
        transform: translate3d(0, 0px, 0);
        transition: 0.4s ease-in-out;
        box-shadow: 0px 6px 18px 0px rgba(11, 7, 110, 0.07);
        display: flex;
    }

    .slide-1 .active-slide-indicator {
        transform: translate3d(-10px, 0px, 0);
    }

    .slide-2 .active-slide-indicator {
        transform: translate3d(-10px, 84px, 0);
    }

    .slide-3 .active-slide-indicator {
        transform: translate3d(-10px, 168px, 0);
    }

    .slide-4 .active-slide-indicator {
        transform: translate3d(-10px, 252px, 0);
    }
}

.slide-wrapper {
    flex-basis: 100%;
    flex-shrink: 0;
    opacity: 0;
}

.brand-payout-timeline {
    transform: translate3d(-100%, 0, 0) !important;
}

.brand-platform-goals {
    transform: translate3d(-200%, 0, 0);
}

.brand-balanced-portfolio {
    transform: translate3d(-300%, 0, 0);
}

/* .slide-wrapper h4 {
    opacity: 0;
    transition: opacity 0.5s ease-in;
} */

.menu-item .active {
    display: none;
}

.menu-item .inactive {
    display: block;
}

.active-menu-item .inactive {
    display: none;
}

.active-menu-item .active {
    display: block;
}

.slide-right-in-left.slide-1-out .brand-graph-slide,
.slide-right-in-left.slide-2-out .brand-payout-timeline,
.slide-right-in-left.slide-3-out .brand-platform-goals,
.slide-right-in-left.slide-4-out .brand-balanced-portfolio,
.slide-right-in-left.slide-1-in.slide-1 .brand-graph-slide,
.slide-right-in-left.slide-2-in.slide-2 .brand-payout-timeline,
.slide-right-in-left.slide-3-in.slide-3 .brand-platform-goals,
.slide-right-in-left.slide-4-in.slide-4 .brand-balanced-portfolio,
.slide-left-in-right.slide-1-out .brand-graph-slide,
.slide-left-in-right.slide-2-out .brand-payout-timeline,
.slide-left-in-right.slide-3-out .brand-platform-goals,
.slide-left-in-right.slide-4-out .brand-balanced-portfolio,
.slide-left-in-right.slide-1-in.slide-1 .brand-graph-slide,
.slide-left-in-right.slide-2-in.slide-2 .brand-payout-timeline,
.slide-left-in-right.slide-3-in.slide-3 .brand-platform-goals,
.slide-left-in-right.slide-4-in.slide-4 .brand-balanced-portfolio {
    animation-duration: 0.75s;
    animation-iteration-count: 1;
    animation-fill-mode: none;
}

.slide-right-in-left.slide-1-out .brand-graph-slide,
.slide-right-in-left.slide-2-out .brand-payout-timeline,
.slide-right-in-left.slide-3-out .brand-platform-goals,
.slide-right-in-left.slide-4-out .brand-balanced-portfolio {
    animation-name: slide-out-right-brand-usp;
    opacity: 0;
}

.slide-right-in-left.slide-1-in.slide-1 .brand-graph-slide,
.slide-right-in-left.slide-2-in.slide-2 .brand-payout-timeline,
.slide-right-in-left.slide-3-in.slide-3 .brand-platform-goals,
.slide-right-in-left.slide-4-in.slide-4 .brand-balanced-portfolio {
    animation-name: slide-in-right-brand-usp;
    opacity: 1;
}

.slide-left-in-right.slide-1-out .brand-graph-slide,
.slide-left-in-right.slide-2-out .brand-payout-timeline,
.slide-left-in-right.slide-3-out .brand-platform-goals,
.slide-left-in-right.slide-4-out .brand-balanced-portfolio {
    animation-name: slide-out-left-brand-usp;
    opacity: 0;
}

.slide-left-in-right.slide-1-in.slide-1 .brand-graph-slide,
.slide-left-in-right.slide-2-in.slide-2 .brand-payout-timeline,
.slide-left-in-right.slide-3-in.slide-3 .brand-platform-goals,
.slide-left-in-right.slide-4-in.slide-4 .brand-balanced-portfolio{
    animation-name: slide-in-left-brand-usp;
    opacity: 1;
}

@keyframes slide-out-right-brand-usp {
    0% {
        right: 0;
        opacity: 0.6;
    }
    100% {
        right: 100%;
        opacity: 0;
    }
}

@keyframes slide-in-right-brand-usp {
    0% {
        left: 100%;
        opacity: 0;
    }
    100% {
        left: 0;
        opacity: 1;
    }
}

@keyframes slide-out-left-brand-usp {
    0% {
        left: 0;
        opacity: 0.6;
    }
    100% {
        left: 100%;
        opacity: 0;
    }
}

@keyframes slide-in-left-brand-usp {
    0% {
        right: 100%;
        opacity: 0;
    }
    100% {
        right: 0;
        opacity: 1;
    }
}

/* SLIDE ANIMATIONS */
@import './brandFeatures/backgroundImagePositions.css';
@import './brandFeatures/brandGraph.css';
@import './brandFeatures/brandTimeline.css';
@import './brandFeatures/brandPlatform.css';
@import './brandFeatures/brandBalancedPortfolio.css';
