.jiraaf-earn-returns {
    transform: translate3d(-60px, 50px, 0px);
}

/* todo will remove */
@media (min-width: 1024px) and (max-width: 1280px) {
    .jiraaf-earn-returns {
        transform: translate3d(-40px, 64px, 0px) scale(1) !important;
    }
}

@media (min-width: 767px) and (max-width: 1024px) {
    .jiraaf-earn-returns {
        transform: translate3d(-3px, -179px, 0px) scale(1.15);
    }
}

@media (max-width: 860px) {
    .jiraaf-earn-returns {
        transform: translate3d(-3px, -129px, 0px) scale(1.11);
    }
}

@media (max-width: 767px) {
    .jiraaf-earn-returns {
        transform: translate3d(-3px, -92px, 0px) scale(1.2);
    }
}

@media (max-width: 640px) {
    .jiraaf-earn-returns {
        transform: translate3d(3px, -95px, 0) scale(1.26);
    }
}

@media (max-width: 500px) {
    .jiraaf-earn-returns {
        transform: translate3d(3px, -60px, 0) scale(1.26);
    }
}

@media (max-width: 424px) {
    .jiraaf-earn-returns {
        transform: translate3d(3px, -46px, 0) scale(1.26);
    }
}
/* todo will remove */
