.snap-scroll-section-video-banner {
    scroll-snap-align: start;
}

.snap-scroll-section {
    min-height: 100vh;
    scroll-snap-align: start;
}

@media (min-width: 768px) {
    .snap-scroll-section {
        height: calc(var(--vh, 1vh) * 100);
        min-height: 500px;
    }
}

@media (min-width: 1280px) {
    .snap-scroll-section {
        min-height: calc(var(--vh, 1vh) * 100);
    }
}

.text-active-color {
    color: theme('colors.primary[500]') !important;
}

.new-navbar-shadow {
    margin: 0px !important;
    height: 80px;
}

.slide-up {
    box-shadow: 0px 4px 54px rgba(0, 0, 0, 0.09);
    backdrop-filter: blur(10px);
    transform: translate3d(0, -100%, 0);
}

.scrollbar-hide {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

@media (min-width: 1280px) {
    .homepage-wrapper .container {
        max-width: 1200px;
    }
}

@media (min-width: 1280px) and (max-height: 900px) {
    #homepageWrapper > section + section {
        zoom: 0.9;
    }

    .zoom-adjustment {
        zoom: 0.9;
    }
}
