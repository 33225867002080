@font-face {
    src: url('/fonts/general-sans/GeneralSans-Light.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 300;
    font-style: normal;
}

@font-face {
    src: url('/fonts/general-sans/GeneralSans-Regular.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 400;
    font-style: normal;
}

@font-face {
    src: url('/fonts/general-sans/GeneralSans-Medium.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 500;
    font-style: normal;
}

@font-face {
    src: url('/fonts/general-sans/GeneralSans-Semibold.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 600;
    font-style: normal;
}

@font-face {
    src: url('/fonts/general-sans/GeneralSans-Bold.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 700;
    font-style: normal;
}

@font-face {
    src: url('/fonts/general-sans/GeneralSans-Bold.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 800;
    font-style: normal;
}

@font-face {
    src: url('/fonts/general-sans/GeneralSans-Bold.woff2') format('woff2');
    font-family: 'GeneralSans';
    font-weight: 900;
    font-style: normal;
}