.start-investing-box {
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 64px 44px rgb(0 0 0 / 15%);
    backdrop-filter: blur(64px);
}

.start-investing-box button {
    border: 0.5px solid theme('colors.basicBlack/20%');
    box-sizing: border-box;
}


.input-box span {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(34px);
}

.input-box input {
    box-shadow: 0px 64px 44px rgba(0, 0, 0, 0.15);
    color: theme('colors.gray[400]');
}

@media (max-width: 1023px) {
    .start-investing-box {
        left: 16%;
        bottom: 5%;
    }

    .bouncing-ball-steps-graphic-container {
        top: 10%;
        left: 20%;
    }
}

@media (max-width: 769px) {
    .start-investing-box {
        left: 16%;
    }

    .bouncing-ball-steps-graphic-container {
        top: 14%;
        left: 30%;
    }
}

@media (max-width: 640px) {
    .input-box input {
        box-shadow: inset 0px 0.460432px 0.460432px rgb(0 0 0 / 8%);
    }

    .input-box span {
        background: rgba(255, 255, 255, 0.4);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        backdrop-filter: blur(34px);
    }

    .start-investing-box img {
        width: 3px;
    }

    .start-investing-box {
        border-radius: 4px;
        height: 75px;
    }

    .start-investing-box p {
        transform: translate3d(0, -10px, 0);
    }

    .mobile-custom-box {
        transform: translate3d(0%, 38%, 0);
    }

    .start-investing-box {
        right: 10%;
        left: unset;
        bottom: -60%;
    }

    .custom-square {
        left: -15px;
        bottom: -50%;
    }
}

@media (max-width: 440px) {
    .start-investing-box {
        left: 49%;
        bottom: -75%;
    }

    .mobile-custom-box {
        transform: translate3d(0%, 54%, 0);
    }

    .bouncing-ball-steps-graphic-container {
        top: 10%;
        left: 15%;
    }

    .custom-square {
        left: -10px;
        bottom: -66%;
    }
}