.jiraaf-top-rectangle,
.jiraaf-content-area-dark-rectangle,
.jiraaf-rectangle-area-light-rectangle {
    transition: 0.5s ease-in-out;
}
.slide-1.jiraaf-top-rectangle {
    transform: translate3d(0, -70px, 0);
}

/* content area dark rectangle */
.slide-1 .jiraaf-content-area-dark-rectangle {
    transform: translate3d(563%, 86%, 0);
}
.slide-2 .jiraaf-content-area-dark-rectangle {
    transform: translate3d(365%, 20%, 0);
}
.slide-3 .jiraaf-content-area-dark-rectangle {
    transform: translate3d(310%, 100%, 0);
}
.slide-4 .jiraaf-content-area-dark-rectangle {
    transform: translate3d(250%, 437%, 0);
}

/* content area light rectangle */
.slide-1 .jiraaf-rectangle-area-light-rectangle {
    transform: translate3d(126%, 190%, 0);
}
.slide-2 .jiraaf-rectangle-area-light-rectangle {
    transform: translate3d(312%, 184%, 0);
}
.slide-3 .jiraaf-rectangle-area-light-rectangle {
    transform: translate3d(390%, 95%, 0);
}
.slide-4 .jiraaf-rectangle-area-light-rectangle {
    transform: translate3d(349%, -18%, 0);
}

@media (max-width: 1024px) {
    .slide-4.jiraaf-top-rectangle {
        transform: translate3d(0, -70px, 0);
    }
   
    /* content area dark rectangle */
    .slide-1 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(657%, -10%, 0);
    }
    .slide-2 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(709%, -56%, 0);
    }
    .slide-3 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(606%, 110%, 0);
    }
    .slide-4 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(726%, 60%, 0);
    }

    /* content area light rectangle */
    .slide-1 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(189%, 280%, 0);
    }
    .slide-2 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(402%, 256%, 0);
    }
    .slide-3 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(354%, 350%, 0);
    }
    .slide-4 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(520%, 350%, 0);
    }
}
@media (max-width: 767px) {
    .slide-1.jiraaf-top-rectangle {
        transform: translate3d(0, -70px, 0);
    }
    .slide-2.jiraaf-top-rectangle {
        transform: translate3d(0, 70%, 0);
    }
    .slide-3.jiraaf-top-rectangle {
        transform: translate3d(0, 20%, 0);
    }
    .slide-4.jiraaf-top-rectangle {
        transform: translate3d(0, 100%, 0);
    }
    
    /* content area dark rectangle */
    .slide-1 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(726%, -16%, 0);
    }
    .slide-2 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(656%, -60%, 0);
    }
    .slide-3 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(726%, 160%, 0);
    }
    .slide-4 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(656%, 30%, 0);
    }

    /* content area light rectangle */
    .slide-1 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(120%, 280%, 0);
    }
    .slide-2 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(351%, 200%, 0);
    }
    .slide-3 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(350%, 326%, 0);
    }
    .slide-4 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(454%, 400%, 0);
    }
}
@media (max-width: 640px) {
    .slide-1.jiraaf-top-rectangle {
        transform: translate3d(0, 10px, 0);
    }
    .slide-2.jiraaf-top-rectangle {
        transform: translate3d(0, 100%, 0);
    }
    .slide-3.jiraaf-top-rectangle {
        transform: translate3d(0, 100%, 0);
    }

    /* content area dark rectangle */
    .slide-1 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(837%, -16%, 0);
    }
    .slide-2 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(826%, -60%, 0);
    }
    .slide-3 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(826%, 160%, 0);
    }
    
    /* content area light rectangle */
    .slide-1 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(120%, 350%, 0);
    }
    .slide-2 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(411%, 200%, 0);
    }
    .slide-3 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(440%, 286%, 0);
    }
}
@media (max-width: 500px) {
    .slide-1.jiraaf-top-rectangle {
        transform: translate3d(0, 80px, 0);
    }
    .slide-4.jiraaf-top-rectangle {
        transform: translate3d(0, 100%, 0);
    }

    /* content area dark rectangle */
    .slide-1 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(757%, -16%, 0);
    }
    .slide-2 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(686%, -60%, 0);
    }
    .slide-3 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(726%, 160%, 0);
    }
    .slide-4 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(706%, 110%, 0);
    }

    /* content area light rectangle */
    .slide-1 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(105%, 299%, 0);
    }
    .slide-2 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(300%, 200%, 0);
    }
    .slide-3 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(340%, 356%, 0);
    }
    .slide-4 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(404%, 400%, 0);
    }
}
@media (max-width: 420px) {
    .slide-1.jiraaf-top-rectangle {
        transform: translate3d(-37px, 60px, 0);
    }
    .slide-2.jiraaf-top-rectangle {
        transform: translate3d(-10px, 204%, 0);
    }
    .slide-3.jiraaf-top-rectangle {
        transform: translate3d(-10px, 104%, 0);
    }
    .slide-4.jiraaf-top-rectangle {
        transform: translate3d(-26px, 128%, 0);
    }

    /* content area dark rectangle */
    .slide-1 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(600%, -14%, 0);
    }
    .slide-2 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(590%, -60%, 0);
    }
    .slide-3 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(608%, 160%, 0);
    }
    .slide-4 .jiraaf-content-area-dark-rectangle {
        transform: translate3d(590%, 120%, 0);
    }

    /* content area light rectangle */

    .slide-1 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(82%, 242%, 0);
    }
    .slide-2 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(265%, 213%, 0);
    }
    .slide-3 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(294%, 290%, 0);
    }
    .slide-4 .jiraaf-rectangle-area-light-rectangle {
        transform: translate3d(297%, 318%, 0);
    }
}