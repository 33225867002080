.testimonials-slider {
    transition-timing-function: linear;
}

.testimonials .recent-deals-slider {
    height: 100%;
}
.testimonials .action-buttons button {
    filter: drop-shadow(0px 16.2857px 16.2857px rgba(0, 0, 0, 0.07));
}

@media (min-width: 389px) and (max-width: 400px) {
    .testimonial-card-wrapper {
        margin-left: 2%;
    }
}
@media (min-width: 401px) and (max-width: 767px) {
    .testimonial-card-wrapper {
        margin-left: 3%;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .testimonial-card-wrapper {
        margin-left: 3%;
    }
}

.testimonials .swiper-wrapper {
    transition-timing-function: linear;
}

.testimonials .swiper {
    overflow: visible !important;
}

.testimonials .recent-deals-slider > li {
    padding: 0 10px 0 0;
}
.testimonial-card {
    transition: all 5000ms ease;
}
@media (min-width: 640px) {
    .testimonials .recent-deals-slider > li {
        padding: 0 40px 0 0;
    }
}
